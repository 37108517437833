import { useState } from 'react';
import { FaMagnifyingGlass } from "react-icons/fa6";

import { getReceiptsByArticleReport, getProviderReceiptsDetails } from "../services/ReportsService";
import { exportReceiptsByArticleReport, exportReceiptProviderDetailsReport } from "../services/ExportService";
import TableContainer from "../components/shared/TableContainer";
import { formatCurrency } from "../utils/numbers";
import { AddButton, TableActionButton } from "../styles/StyledComponents";

const ReportReceiptsByArticle = () => {
    // ----- State
    let [showProviderDetailsTable, setShowProviderDetailsTable] = useState(false);
    let [currentProvider, setCurrentProvider] = useState({});
    let [parentFilters, setParentFilters] = useState([]);
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Actions
    const handleShowProviderDetailsTable = (providerId, name) => {
        setShowProviderDetailsTable(false);

        setTimeout(() => {
            setCurrentProvider({ providerId, name });
            setUpdateTable(!updateTable);
            setShowProviderDetailsTable(true);
        }, 100);
    }

    const handleDataAfterFiltering = (filters) => {
        let parentFilters = JSON.parse(JSON.stringify(filters));
        parentFilters = parentFilters.filter(pf => pf.columnName === 'article' || pf.columnName === 'eventDate').map(f => {
            if (f.columnName === 'article') {
                f.columnName = 'parentArticle'
            }
            return f;
        });
        setParentFilters(parentFilters);

        setShowProviderDetailsTable(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowProviderDetailsTable(row.id, row.provider)}>
                <FaMagnifyingGlass />
                Ver Detalles
            </TableActionButton>
        </div>
    );

    const columns = [
        {
            name: 'Proveedor',
            selector: row => row.provider,
            sortable: true,
            sortField: 'provider',
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: 'Valor Total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
        {
            name: 'Acciones',
            width: "150px",
            cell: row => <Actions row={row} />,
        }
    ];

    const filters = [
        {
            column: 'article',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'provider',
            input: 'text',
            label: 'Proveedor',
            operation: 'like',
            length: 50
        },
        {
            column: 'totalValue',
            input: 'range',
            label: 'Valor Total',
            operation: 'between',
        },
        {
            column: 'eventDate',
            input: 'dates',
            label: 'Fecha de Compra',
            operation: 'between',
        },
    ];

    const exportOptions = {
        handler: exportReceiptsByArticleReport
    };

    const exportDetailstOptions = {
        handler: exportReceiptProviderDetailsReport
    };

    const providerDetailsTableColumns = [
        {
            name: 'Artículo',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Marca',
            selector: row => row.brand,
            sortable: true,
            sortField: 'brand',
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: 'Precio promedio',
            selector: row => `₡${formatCurrency(row.averageValue)}`,
            sortable: true,
            sortField: 'averageValue',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
    ];

    const providerDetailsTableFilters = [
        {
            column: 'name',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'brand',
            input: 'text',
            label: 'Marca',
            operation: 'like',
            length: 50
        },
    ];


    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte de Compras por Artículo</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getReceiptsByArticleReport}
                updateTable={true}
                filters={filters}
                afterFilteringHandler={handleDataAfterFiltering}
                exportOptions={exportOptions}
            />

            {showProviderDetailsTable &&
                <div className='my-5'>
                    <h3>Detalles para proveedor: {currentProvider.name}</h3>

                    <div className="d-flex justify-content-end">
                        <AddButton className="btn btn-primary" onClick={() => setShowProviderDetailsTable(false)}>
                            Ocultar detalles
                        </AddButton>
                    </div>

                    <TableContainer
                        columns={providerDetailsTableColumns}
                        fetchDataHandler={getProviderReceiptsDetails}
                        updateTable={updateTable}
                        filters={providerDetailsTableFilters}
                        pathVariables={currentProvider}
                        exportOptions={exportDetailstOptions}
                        parentFilters={parentFilters}
                    />
                </div>
            }

        </div>
    )
}

export default ReportReceiptsByArticle;