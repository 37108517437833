import React, { createContext, useContext, useMemo, useState } from 'react'

const ContextApi = createContext(null)

const Provider = ({ children }) => {
    const [loggedUser, setLoggedUser] = useState(JSON.parse(localStorage.getItem("loggedUser")) || null)
    const [loggedInTime, setLoggedInTime] = useState(JSON.parse(localStorage.getItem("loggedInTime")) || null)
    const [lastActionTime, setLastActionTime] = useState(JSON.parse(localStorage.getItem("lastActionTime")) || null)

    // Values that will be visible in the context
    const values = useMemo(() => ({
        loggedUser, setLoggedUser, loggedInTime, setLoggedInTime, lastActionTime, setLastActionTime
    }), [loggedUser, loggedInTime, lastActionTime]);

    return (
        <ContextApi.Provider value={values}>
            {children}
        </ContextApi.Provider>
    )
};

function useAppContext() {
    const context = useContext(ContextApi);

    if (!context) {
        console.error('Error deploying App Context!!!');
    }

    return context;
}

export { useAppContext, Provider };
