import { RouterProvider } from "react-router-dom";

import { router } from './router/Router';

const App = () => {
  // ----- Render
  return <RouterProvider router={router} />
}

export default App;
