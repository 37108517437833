import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import { useForm } from "react-hook-form"

import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";

import { useAppContext } from '../contextApi/context'
import { login } from "../services/AuthService"
import { BLOCKED_BY_CHANGE_PASSWORD } from "../constants/apiMessages"
import logo from "../assets/img/logo-192x192.png"
import { NoAuthFormContainer } from "../styles/StyledComponents"

const Login = () => {
    // ----- Context/Hooks
    const { loggedUser, setLoggedUser, setLoggedInTime, setLastActionTime } = useAppContext();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if(!localStorage.getItem("loggedUser")) {
            localStorage.clear();
            setLoggedUser(null);
        }
        
        if (loggedUser != null) {
            navigate("/dashboard")
        }
    }, [loggedUser, navigate, setLoggedUser]);

    // ----- State
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // ----- Actions
    const handleLogin = async (data) => {
        setShowError(false);
        const { username, password } = data;

        const response = await login(username, password);
        if (response.token) {
            localStorage.setItem("loggedUser", JSON.stringify(response));
            setLoggedUser(response);

            let loggedInTime = Date.now();
            localStorage.setItem("loggedInTime", loggedInTime);
            setLoggedInTime(loggedInTime);
            localStorage.setItem("lastActionTime", loggedInTime);
            setLastActionTime(loggedInTime);
            navigate("/dashboard");
        } else {
            // Error from API
            const { data } = response;

            // Wrong User or password
            if (response.status === 403 || response.status === 400) {
                setShowError(true);
            }

            if (data === BLOCKED_BY_CHANGE_PASSWORD) {
                navigate("/cambiar-contrasena");
            }
        }
    }

    const togglePasswordShow = () => {
        setShowPassword(!showPassword)
    }

    // ----- Render
    return (
        <MainContainer className="container">
            <Logo src={logo} />
            <NoAuthFormContainer className="shadow card p-4">
                {showError &&
                    <div className="alert alert-danger text-center mb-1" role="alert">
                        Usuario o contraseña incorrectos.
                    </div>
                }

                <h2 className="mb-4 text-center">Iniciar sesión</h2>
                <p className="text-muted text-center mb-4">Ingrese su nombre de usuario y contraseña para accesar al sistema.</p>

                <form onSubmit={handleSubmit(handleLogin)}>
                    <label className="d-flex mb-1" htmlFor="username">Nombre de usuario</label>
                    <input maxLength={50} className="form-control" type="text" id="username" {...register("username", { required: true, maxLength: 50 })} autoFocus autoComplete="off" />
                    <span className="my-1" role="alert">
                        {errors.username?.type === "required" && `Campo requerido`}
                        {errors.username?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <label className="d-flex mb-1" htmlFor="password">Contraseña</label>
                    <div className="d-flex align-items-center position-relative">
                        <input maxLength={50} className="form-control" type={showPassword ? "text" : "password"} id="password" autoComplete="off" {...register("password", { required: true, maxLength: 50 })} />
                        {showPassword ? <BiHide className="ms-2" onClick={togglePasswordShow} /> : <BiShow className="ms-2" onClick={togglePasswordShow} />}
                    </div>
                    <span className="my-1" role="alert">
                        {errors.password?.type === "required" && `Campo requerido`}
                        {errors.password?.type === "maxLength" && `Formato inválido`}
                    </span>

                    <button type="submit" className="btn btn-danger mt-3">Ingresar</button>
                </form>
                <div className="text-center mt-3">
                    <Link to="/recuperar-contrasena">Recuperar contraseña</Link>
                </div>
            </NoAuthFormContainer>
        </MainContainer>
    )
}

// ----- Styles
const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    svg {
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            right: 4px;
        }
`;

const Logo = styled.img`
    height: 192px;
    margin: 0 auto 30px;
    width: 192px;
`;

export default Login;