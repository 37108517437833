import { useState } from "react"
import { Link } from "react-router-dom"

import { MdMenu } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiInboxUnarchiveLine } from "react-icons/ri";
import { RiInboxArchiveLine } from "react-icons/ri";
// import { IoMdNotificationsOutline } from "react-icons/io";
// import { FaHome } from "react-icons/fa";
import { FiFolderPlus } from "react-icons/fi";
import { AiOutlineStock } from "react-icons/ai";
// import { TbLogout2 } from "react-icons/tb";

import { useAppContext } from '../../contextApi/context'
import { checkPermission, checkReportPermissions } from "../../utils/permissions";


const Sidebar = () => {

    // ----- Const
    const { loggedUser } = useAppContext();

    // ----- State
    const [expand, setExpand] = useState(true);

    // ----- Actions
    const handleToggle = () => {
        setExpand(!expand)
    }

    // ----- Render
    return (
        <nav id="sidebar" className={expand ? "expand" : ""}>
            <div className="d-flex">
                <button className="toggle-btn" onClick={handleToggle} type="button">
                    <MdMenu />
                </button>
                <div className="sidebar-logo">
                    <span>Menu</span>
                </div>
            </div>
            <ul className="sidebar-nav">
                {/* <li className="sidebar-item">
                    <Link to='/dashboard' className="sidebar-link">
                        <FaHome />
                        <span>Dashboard</span>
                    </Link>
                </li> */}
                {checkPermission(loggedUser.permissions, 'view_stock') &&
                    <li className="sidebar-item">
                        <Link to='/inventario' className="sidebar-link">
                            <AiOutlineStock />
                            <span>Inventario</span>
                        </Link>
                    </li>
                }
                {checkPermission(loggedUser.permissions, 'view_stock_receipt') &&
                    <li className="sidebar-item">
                        <Link to='/compras' className="sidebar-link">
                            <RiInboxArchiveLine />
                            <span>Compras</span>
                        </Link>
                    </li>
                }
                {checkPermission(loggedUser.permissions, 'view_stock_issue') &&
                    <li className="sidebar-item">
                        <Link to='/despacho' className="sidebar-link">
                            <RiInboxUnarchiveLine />
                            <span>Despacho</span>
                        </Link>
                    </li>
                }
                {checkReportPermissions(loggedUser.permissions) && 
                    <li className="sidebar-item">
                        <a href="/" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                            data-bs-target="#reports" aria-expanded="false" aria-controls="reports">
                            <HiOutlineDocumentReport />
                            <span>Reportes</span>
                        </a>
                        <ul id="reports" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                            {checkPermission(loggedUser.permissions, 'view_financial_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-financiero" className="sidebar-link">Reporte Financiero</Link>
                                </li>
                            }
                            {checkPermission(loggedUser.permissions, 'view_stock_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-existencias" className="sidebar-link">Existencias en Tiempo Real</Link>
                                </li>
                            }
                            {checkPermission(loggedUser.permissions, 'view_movements_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-ajustes" className="sidebar-link">Ajustes de Inventario</Link>
                                </li>
                            }
                            {checkPermission(loggedUser.permissions, 'view_minimums_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-minimos" className="sidebar-link">Inventario en Riesgo</Link>
                                </li>
                            }
                            {checkPermission(loggedUser.permissions, 'view_article_receipt_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-compras" className="sidebar-link">Compras por Artículo</Link>
                                </li>
                            }
                            {checkPermission(loggedUser.permissions, 'view_vehicle_issue_report') &&
                                <li className="sidebar-item">
                                    <Link to="/reporte-despachos" className="sidebar-link">Despachos por Placa</Link>
                                </li>
                            }
                        </ul>
                    </li>
                }
                <li className="sidebar-item">
                    <a href="/" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                        data-bs-target="#cruds" aria-expanded="false" aria-controls="cruds">
                        <FiFolderPlus />
                        <span>Mantenimientos</span>
                    </a>
                    <ul id="cruds" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                        {checkPermission(loggedUser.permissions, 'view_articles') &&
                            <li className="sidebar-item">
                                <Link to="/articulos" className="sidebar-link">Artículos</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_clients') &&
                            <li className="sidebar-item">
                                <Link to="/clientes" className="sidebar-link">Clientes</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_tags') &&
                            <li className="sidebar-item">
                                <Link to="/etiquetas" className="sidebar-link">Etiquetas</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_materials') &&
                            <li className="sidebar-item">
                                <Link to="/materiales" className="sidebar-link">Materiales</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_providers') &&
                            <li className="sidebar-item">
                                <Link to="/proveedores" className="sidebar-link">Proveedores</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_roles') &&
                            <li className="sidebar-item">
                                <Link to="/roles" className="sidebar-link">Roles</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_locations') &&
                            <li className="sidebar-item">
                                <Link to="/ubicaciones" className="sidebar-link">Ubicaciones</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_users') &&
                            <li className="sidebar-item">
                                <Link to="/usuarios" className="sidebar-link">Usuarios</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'view_vehicles') &&
                            <li className="sidebar-item">
                                <Link to="/vehiculos" className="sidebar-link">Vehículos</Link>
                            </li>
                        }
                        {checkPermission(loggedUser.permissions, 'manage_exchange') &&
                            <li className="sidebar-item">
                                <Link to="/tipo-cambio" className="sidebar-link">Tipo de Cambio</Link>
                            </li>
                        }
                    </ul>
                </li>
                {/* <li className="sidebar-item">
                    <Link to="/notificaciones" className="sidebar-link">
                        <IoMdNotificationsOutline />
                        <span>Notificaciones</span>
                    </Link>
                </li> */}
            </ul>
            {/* <div className="sidebar-footer">
                <a href="#" className="sidebar-link">
                    <TbLogout2 />
                    <span>Salir</span>
                </a>
            </div> */}
        </nav>
    );
}

export default Sidebar;
