import { useEffect, useState } from "react";
import { styled } from "styled-components";
import moment from 'moment';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FaFilter } from "react-icons/fa";

import { useAppContext } from "../../contextApi/context";
import CustomDatePicker from "./CustomDatePicker";
import { searchTagsByName } from "../../services/TagsService"
import TagChip from "./TagChip";

const Filters = ({ filters, filterHandler, parentFilters = [] }) => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [show, setShow] = useState(false);
    const [filtersState, setFiltersState] = useState([]);
    const [now, setNow] = useState(Date.now());
    const [errors, setErrors] = useState({});

    // Tags Filter
    const [timer, setTimer] = useState(null);
    let [availableTags, setAvailableTags] = useState([]);
    let [selectedTags, setSelectedTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState("");

    useEffect(() => {
        let tempFilters = filters.map(item => ({
            columnName: item.column,
            operation: item.operation,
            value: [],
        }))
        
        if (parentFilters.length > 0) {
            tempFilters = tempFilters.concat(parentFilters);
        }
        setFiltersState(tempFilters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ----- Actions
    const handleFilter = () => {
        setErrors({});
        let isError = false;

        let newFilterState = filtersState.filter(item =>
            (item.operation !== 'between' && item.value.length > 0) || (item.operation === 'between' && item.value.length >= 1));

        // Validate filters type Range
        let filterRanges = newFilterState.filter(item => item.operation === 'between');
        filterRanges.forEach(range => {
            errors[range.columnName] = false
            
            let values = range.value;
            if (values.length === 1 || values[0] === undefined || values[0] === null || values[1] === null || values[0] > values[1]) {
                errors[range.columnName] = true
                isError = true
            }
            setErrors(errors);
        })

        if (!isError) {
            // console.log('FILTER STATE : ', filtersState);
            filterHandler(newFilterState);
        }
    }

    const handleClear = () => {
        let newFilterState = filtersState.map(item => {
            if (!parentFilters.includes(item)) {
                item.value = []
            }
            return item;
        })

        setFiltersState(newFilterState);
        setErrors({});
        setSelectedTags([]);
        setNow(Date.now());

        filterHandler(parentFilters);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        let newFilterState = filtersState.map(item => {
            if (item.columnName === name) {
                item.value = value === "" ? [] : [value]
                return item;
            }
            return item;
        })
        setFiltersState(newFilterState);
    }

    const handleRangeChange = (e, fromTo) => {
        let { name, value } = e.target;
        value = value.replace(',', '');

        let newFilterState = filtersState.map(item => {
            let values = item.value;

            if (item.columnName === name) {
                if (fromTo === 'from') {
                    values[0] = value ? parseFloat(value) : null
                }
                else {
                    values[1] = value ? parseFloat(value) : null
                }
                return item;
            }
            return item;
        })
        setFiltersState(newFilterState);
    }

    const handleDatepickerChange = (date, name, fromTo) => {
        let newFilterState = filtersState.map(item => {
            let values = item.value;

            if (item.columnName === name) {

                if (fromTo === 'from') {
                    values[0] = date.replace('06:00:00', '00:00:00')
                }
                else {
                    values[1] = date.replace('06:00:00', '23:59:59')
                }

                item.value = values
            }
            return item;
        })
        setFiltersState(newFilterState);
    }

    const handleSearchTags = (e, newValue, reason) => {
        clearTimeout(timer)

        const newTimer = setTimeout(async () => {

            if (reason === 'clear') {
                setAvailableTags([]);
            }

            if (newValue !== '') {
                if (reason === 'input') {
                    const response = await searchTagsByName(loggedUser.token, newValue);
                    const filteredTags = response.filter(tag => !selectedTags.includes(tag.name));
                    const parsedResponse = filteredTags.map(tag => ({ id: tag.id, label: tag.name, value: tag.name }));
                    setAvailableTags(parsedResponse);
                }
            }
            else {
                setAvailableTags([]);
            }
        }, 500);

        setTimer(newTimer)
    }

    const handleSelectTag = (event, newValue) => {
        if (newValue != null) {
            setSelectedTag(newValue.value)
        }
    }

    const handleAddTag = (name) => {
        if (selectedTag !== "") {
            setSelectedTag('');
            selectedTags.push(selectedTag);
            setSelectedTags(selectedTags);

            let newFilterState = filtersState.map(item => {
                if (item.columnName === name) {
                    item.value = selectedTags
                }
                return item;
            })
            setFiltersState(newFilterState);
        }
    }

    const handleRemoveTag = (_id, tagName, name) => {
        selectedTags = selectedTags.filter(tag => tag !== tagName)
        setSelectedTags(selectedTags);

        let newFilterState = filtersState.map(item => {
            if (item.columnName === name) {
                item.value = selectedTags
            }
            return item;
        })
        setFiltersState(newFilterState);
    }

    // ----- Render
    return (
        <FiltersContainer key={now}>
            <button className="btn" onClick={() => setShow(!show)}>
                <FaFilter /> Filtros
            </button>

            {show &&
                <div>
                    {filters.map((filter, index) => {
                        if (filter.input === 'text') {
                            return (
                                <div key={index} className="filter">
                                    <label className="fw-bold mb-2">{filter.label}</label>
                                    <input type="text" className="form-control form-control-sm" name={filter.column} value={filtersState[index].value} onChange={handleChange} maxLength={filter.length} />
                                </div>
                            )
                        } else if (filter.input === 'select') {
                            return (
                                <div key={index} className="filter">
                                    <label className="fw-bold mb-2">{filter.label}</label>
                                    <select name={filter.column} value={filtersState[index].value} className="form-select form-select-sm" onChange={handleChange}>
                                        <option value="">Todos</option>
                                        {filter.options?.map((option, index) =>
                                            <option key={index} value={option.value}>{option.text}</option>
                                        )}
                                    </select>
                                </div>
                            )
                        } else if (filter.input === 'dates') {
                            return (
                                <div key={index} className="range">
                                    <label className="fw-bold mb-2">{filter.label}</label>
                                    <div className="d-flex align-items-center">
                                        <CustomDatePicker maxDate={moment(Date.now())} actionHandler={(date) => handleDatepickerChange(date, filter.column, 'from')} />
                                        <p>a</p>
                                        <CustomDatePicker maxDate={moment(Date.now())} actionHandler={(date) => handleDatepickerChange(date, filter.column, 'to')} />
                                    </div>
                                    <span className="error">{errors[filter.column] && 'Primera fecha debe ser menor a la segunda'}</span>
                                </div>
                            )
                        } else if (filter.input === 'range') {
                            return (
                                <div key={index} className="range">
                                    <label className="fw-bold mb-2">{filter.label}</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" className="form-control form-control-sm input-range me-2" name={filter.column} onChange={(e) => handleRangeChange(e, 'from')} maxLength={filter.length} />
                                        <p>a</p>
                                        <input type="text" className="form-control form-control-sm input-range" name={filter.column} onChange={(e) => handleRangeChange(e, 'to')} maxLength={filter.length} />
                                    </div>
                                    <span className="error">{errors[filter.column] && 'Primer valor debe ser menor al segundo'}</span>
                                </div>
                            )
                        } else if (filter.input === 'tags') {
                            return (
                                <div key={index} className="tags">
                                    <label className="fw-bold mb-2">{filter.label}</label>
                                    <div className="d-flex align-items-center">
                                        <Autocomplete
                                            value={selectedTag}
                                            disablePortal
                                            id="size-small-outlined"
                                            size="small"
                                            options={availableTags}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 50 }} label="Buscar etiqueta" />}
                                            onChange={handleSelectTag}
                                            onInputChange={handleSearchTags}
                                            noOptionsText={"Buscar etiqueta existente"}
                                            className="me-4"
                                        />
                                        <button className="btn border border-secondary" onClick={() => handleAddTag(filter.column)}>Agregar</button>
                                    </div>
                                    <div className="d-flex my-3">
                                        {selectedTags?.map((tag, index) =>
                                            <TagChip key={index} removeHandler={(id, tagName) => handleRemoveTag(id, tagName, filter.column)} name={tag} />
                                        )}
                                    </div>
                                </div>
                            )
                        }

                        return <></>
                    })}
                    <div className="buttons">
                        <button type="button" onClick={handleFilter} className="btn btn-primary me-2">Aplicar</button>
                        <button type="button" onClick={handleClear} className="btn border border-secondary">Limpiar</button>
                    </div>
                </div>
            }
            <hr />
        </FiltersContainer>
    );
}

const FiltersContainer = styled.div`
    margin: 10px 0;

    > button {
        align-items: center;
        border: 1px solid #A5A7A0;
        display: flex;

        &:hover {
            border: 1px solid #A5A7A0;
        }
         
        > svg {
            margin-right: 8px;
        }
    }

    > div {
        align-items: end;
        display: flex;
        flex-wrap: wrap;
        margin-top: .5rem;
        padding: 1rem 1rem 0;

        .filter {
            display: flex;
            flex-direction: column;
            margin-bottom: 19px;
            margin-right: 1.5rem;
            width: 20%;

            > input {
                width: 90%;
            }
        }

        .range {
            align-items: start;
            display: flex;
            flex-direction: column;
            width: 26%;

            .MuiFormControl-root.MuiTextField-root {
                margin-right: 8px;
                width: 40%;
            }

            .MuiInputBase-root {
                height: 31px;
            }

            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }

            .MuiIconButton-root,
            .clearButton {
                padding: 0;
            }

            .MuiOutlinedInput-input {
                font-size: 12px;
                padding-left: 3px;
                padding-right: 0;
            }
            
            p {
                margin-bottom: 0;
                margin-right: 8px;
            }

            span.error {
                color: #D04437;
                font-size: 13px;
                min-height: 19px;
            } 

            .input-range {
                width: 40%;
            }
        }

        .tags {
            margin-bottom: 5px;
            width: 100%;
            
            .MuiAutocomplete-root {
                width: 250px;
            }

            .MuiFormLabel-root {
                font-size: 13px;
            }

            .MuiInputBase-root {
                padding: 3px;
            }

            .btn {
                font-size: 12px;
                height: 25px;
                padding: 0 5px;
            }
        }

        .buttons {
            display: flex;
            margin-bottom: 19px;

            > button {
                align-items: center;
                display: flex;
                font-size: .9rem;
                height: 31px;
            }
        }
    }
`;

export default Filters;
