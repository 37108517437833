import { Navigate, Outlet } from 'react-router-dom';

import { useAppContext } from '../contextApi/context'
import { checkPermission } from '../utils/permissions';

export default function PrivateRoute({ code }) {
    // ----- Context/Hooks
    const { loggedUser, lastActionTime } = useAppContext();

    // ----- Render
    if (!loggedUser) {
        return <Navigate to="/login" />
    }

    let activityDiffInMinutes = (Date.now() / 60000) - (lastActionTime / 60000);
    if (activityDiffInMinutes >= 29) {
        localStorage.clear();
        return <Navigate to="/login" />
    }

    if (!checkPermission(loggedUser.permissions, code)) {
        return <Navigate to="/pagina-restringida" />
    }

    return <Outlet />
};
