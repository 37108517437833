import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom"

import Layout from "../layout/Layout";
import PrivateRoute from "./PrivateRoute";
import NoPermission from '../pages/NoPermission';

import Dashboard from "../pages/Dashboard";
import Stock from "../pages/Stock";
import Login from "../pages/Login";
import Users from '../pages/Users';
import EditUser from '../pages/EditUser';
import UserProfile from '../pages/UserProfile';
import Roles from '../pages/Roles';
import EditRole from '../pages/EditRole';
import Providers from '../pages/Providers';
import EditProvider from '../pages/EditProvider';
import Clients from '../pages/Clients';
import EditClient from '../pages/EditClient';
import Materials from '../pages/Materials';
import EditMaterial from '../pages/EditMaterial';
import Locations from '../pages/Locations';
import EditLocation from '../pages/EditLocation';
import Vehicles from '../pages/Vehicles';
import EditVehicle from '../pages/EditVehicle';
import Articles from '../pages/Articles';
import EditArticle from '../pages/EditArticle';
import Tags from '../pages/Tags';
import EditTag from '../pages/EditTag';
import ExchangeRate from '../pages/ExchangeRate';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ChangePassword';
import Issues from "../pages/Issues";
import CreateIssueMovement from "../pages/CreateIssueMovement";
import Receipts from '../pages/Receipts';
import CreateReceiptMovement from "../pages/CreateReceiptMovement";
import ReportStock from '../pages/ReportStock';
import ReportMinimumsValues from '../pages/ReportMinimumsValues';
import ReportStockAdjustments from '../pages/ReportStockAdjustments';
import ReportFinancial from '../pages/ReportFinancial';
import ReportReceiptsByArticle from '../pages/ReportReceiptsByArticle';
import ReportIssuesByVehicle from '../pages/ReportIssuesByVehicle';



export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/recuperar-contrasena" element={<ForgotPassword />} />
            <Route path="/cambiar-contrasena" element={<ChangePassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/pagina-restringida" element={<NoPermission />} />
            <Route path="/perfil-usuario" element={<UserProfile />} />

            <Route element={<PrivateRoute code={"view_vehicles"} />}>
                <Route path="/vehiculos" element={<Vehicles />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_vehicles"} />}>
                <Route path="/vehiculos/:id" element={<EditVehicle />} />
            </Route>

            <Route element={<PrivateRoute code={"view_users"} />}>
                <Route path="/usuarios" element={<Users />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_users"} />}>
                <Route path="/usuarios/:id" element={<EditUser />} />
            </Route>

            <Route element={<PrivateRoute code={"view_roles"} />}>
                <Route path="/roles" element={<Roles />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_roles"} />}>
                <Route path="/roles/:id" element={<EditRole />} />
            </Route>

            <Route element={<PrivateRoute code={"view_providers"} />}>
                <Route path="/proveedores" element={<Providers />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_providers"} />}>
                <Route path="/proveedores/:id" element={<EditProvider />} />
            </Route>

            <Route element={<PrivateRoute code={"view_clients"} />}>
                <Route path="/clientes" element={<Clients />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_clients"} />}>
                <Route path="/clientes/:id" element={<EditClient />} />
            </Route>

            <Route element={<PrivateRoute code={"view_materials"} />}>
                <Route path="/materiales" element={<Materials />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_materials"} />}>
                <Route path="/materiales/:id" element={<EditMaterial />} />
            </Route>

            <Route element={<PrivateRoute code={"view_locations"} />}>
                <Route path="/ubicaciones" element={<Locations />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_locations"} />}>
                <Route path="/ubicaciones/:id" element={<EditLocation />} />
            </Route>

            <Route element={<PrivateRoute code={"view_articles"} />}>
                <Route path="/articulos" element={<Articles />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_articles"} />}>
                <Route path="/articulos/:id" element={<EditArticle />} />
            </Route>

            <Route element={<PrivateRoute code={"view_tags"} />}>
                <Route path="/etiquetas" element={<Tags />} />
            </Route>
            <Route element={<PrivateRoute code={"edit_tags"} />}>
                <Route path="/etiquetas/:id" element={<EditTag />} />
            </Route>

            <Route element={<PrivateRoute code={"manage_exchange"} />}>
                <Route path="/tipo-cambio" element={<ExchangeRate />} />
            </Route>

            <Route element={<PrivateRoute code={"view_stock"} />}>
                <Route path="/inventario" element={<Stock />} />
            </Route>

            <Route element={<PrivateRoute code={"view_stock_issue"} />}>
                <Route path="/despacho" element={<Issues />} />
            </Route>
            <Route element={<PrivateRoute code={"manage_stock_issue"} />}>
                <Route path="/crear-despacho" element={<CreateIssueMovement />} />
            </Route>

            <Route element={<PrivateRoute code={"view_stock_receipt"} />}>
                <Route path="/compras" element={<Receipts />} />
            </Route>
            <Route element={<PrivateRoute code={"manage_stock_receipt"} />}>
                <Route path="/crear-compra" element={<CreateReceiptMovement />} />
            </Route>

            <Route element={<PrivateRoute code={"view_financial_report"} />}>
                <Route path="/reporte-financiero" element={<ReportFinancial />} />
            </Route>
            <Route element={<PrivateRoute code={"view_stock_report"} />}>
                <Route path="/reporte-existencias" element={<ReportStock />} />
            </Route>
            <Route element={<PrivateRoute code={"view_minimums_report"} />}>
                <Route path="/reporte-minimos" element={<ReportMinimumsValues />} />
            </Route>
            <Route element={<PrivateRoute code={"view_movements_report"} />}>
                <Route path="/reporte-ajustes" element={<ReportStockAdjustments />} />
            </Route>
            <Route element={<PrivateRoute code={"view_article_receipt_report"} />}>
                <Route path="/reporte-compras" element={<ReportReceiptsByArticle />} />
            </Route>
            <Route element={<PrivateRoute code={"view_vehicle_issue_report"} />}>
                <Route path="/reporte-despachos" element={<ReportIssuesByVehicle />} />
            </Route>
        </Route>
    )
);