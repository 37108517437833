import { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';
import { PiExport } from "react-icons/pi";

import { useAppContext } from "../../contextApi/context"
import Spinner from "./Spinner";
import { paginationOptions } from "../../constants/tableConfig";
import Filters from './Filters';
import { ExportContainer } from '../../styles/StyledComponents';
import ExportAddFileNameModal from './ExportAddFileNameModal';


const TableContainer = ({ columns, fetchDataHandler, updateTable, filters, defaultSort, pathVariables, afterFilteringHandler, exportOptions, parentFilters = [] }) => {
    // ----- Context/Hooks
    const { loggedUser } = useAppContext();

    // ----- State
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [sortField, setSortField] = useState(null);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [tablePage, setTablePage] = useState(1);
    const [filterData, setFilterData] = useState(parentFilters);

    const [showExportSent, setShowExportSent] = useState(false);
    const [showAddNameModal, setShowAddNameModal] = useState(false);

    // ----- Hooks 
    useEffect(() => {
        setShowExportSent(false);
        handleFetchData(tablePage, perPage, sortField, filterData, pathVariables);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tablePage, perPage, sortField, filterData]);

    useEffect(() => {
        setShowExportSent(false);
        if (data.length === 1 && tablePage > 1) {
            handleFetchData(tablePage - 1, perPage, sortField, filterData, pathVariables);
        } else {
            handleFetchData(tablePage, perPage, sortField, filterData, pathVariables);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTable]);

    // ----- Actions
    const handleSort = (column, sortDirection) => {
        if (column.sortField) {
            setSortField(`${column.sortField},${sortDirection}`);
        }
    };

    const handlePageChange = (page) => {
        if (page !== tablePage) {
            setTablePage(page);
        }
    };

    const handlePerRowsChange = (newPerPage, page) => {
        if (newPerPage !== perPage) {
            setTablePage(page);
            setPerPage(newPerPage);
        }
    };

    const handleFetchData = async (tablePage, perPage = 10, sortField = null, filtersData = [], pathVariables = null) => {
        setLoading(true);
        let response;
        if (pathVariables) {
            response = await fetchDataHandler(loggedUser.token, tablePage, perPage, sortField, filtersData, pathVariables);
        }
        else {
            response = await fetchDataHandler(loggedUser.token, tablePage, perPage, sortField, filtersData);
        }
        setData(response.content);
        setTotalRows(response.totalElements);
        setLoading(false);
    };

    const handleApplyFilters = (data) => {
        setFilterData(data);
        if (afterFilteringHandler) {
            afterFilteringHandler(data);
        }
    }

    const handleExportData = async (filename) => {
        let response;
        if (pathVariables) {
            response = await exportOptions.handler(loggedUser.token, filterData, filename, pathVariables);
        }
        else {
            response = await exportOptions.handler(loggedUser.token, filterData, filename);
        }

        if (response.status === 202) {
            setShowExportSent(true);
        }

        setShowAddNameModal(false);
    }

    const handleOpenAddName = () => {
        setShowAddNameModal(true);
    }
    
    const handleCloseAddName = () => {
        setShowAddNameModal(false);
    }

    // ----- Render
    return (
        <>
            {showExportSent &&
                <div className="alert alert-success text-center mt-3" role="alert">
                    Archivo generado satisfactoriamente. Ha sido enviado al siguiente correo electrónico: {loggedUser.email}
                </div>
            }

            {filters &&
                <Filters filters={filters} parentFilters={parentFilters} filterHandler={handleApplyFilters} />
            }

            {exportOptions != null &&
                <ExportContainer>
                    <button className="btn" onClick={handleOpenAddName}>
                        <PiExport /> Exportar datos
                    </button>
                    {showAddNameModal &&
                        <ExportAddFileNameModal
                            closeHandler={handleCloseAddName}
                            exportHandler={handleExportData}
                            show={showAddNameModal}
                        />
                    }
                </ExportContainer>
            }

            <DataTable
                defaultSortFieldId={defaultSort ? defaultSort : null}
                defaultSortAsc={false}
                columns={columns}
                data={data}
                pagination
                paginationServer
                sortServer
                onSort={handleSort}
                progressPending={loading}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                persistTableHead
                progressComponent={<Spinner />}
                paginationComponentOptions={paginationOptions}
                noDataComponent={<p className="my-5">No hay datos que mostrar</p>}
            />
        </>
    );
}

export default TableContainer;
