import { useState } from 'react';
import { FaMagnifyingGlass } from "react-icons/fa6";

import { getIssuesByVehicleReport, getVehicleIssuesDetails } from "../services/ReportsService";
import { exportIssuesByVehicleReport, exportIssueVehicleDetailsReport } from "../services/ExportService";
import TableContainer from "../components/shared/TableContainer";
import { formatCurrency } from "../utils/numbers";
import { vehicleType } from "../utils/translation";
import { AddButton, TableActionButton } from "../styles/StyledComponents";


const ReportIssuesByVehicle = () => {
    // ----- State
    let [showVehicleDetailsTable, setShowVehicleDetailsTable] = useState(false);
    let [currentVehicle, setCurrentVehicle] = useState({});
    let [parentFilters, setParentFilters] = useState([]);
    const [updateTable, setUpdateTable] = useState(false);

    // ----- Actions
    const handleShowVehicleDetailsTable = (vehicleId, plate) => {
        setShowVehicleDetailsTable(false);

        setTimeout(() => {
            setCurrentVehicle({ vehicleId, plate });
            setUpdateTable(!updateTable);
            setShowVehicleDetailsTable(true);
        }, 100);
    }

    const handleDataAfterFiltering = (filters) => {
        let parentFilters = JSON.parse(JSON.stringify(filters));
        parentFilters = parentFilters.filter(pf => pf.columnName === 'article' || pf.columnName === 'eventDate').map(f => {
            if (f.columnName === 'article') {
                f.columnName = 'parentArticle'
            }
            return f;
        });
        setParentFilters(parentFilters);

        setShowVehicleDetailsTable(false);
    }

    // ----- Constants
    const Actions = ({ row }) => (
        <div className="d-flex">
            <TableActionButton className="btn btn-primary" onClick={() => handleShowVehicleDetailsTable(row.id, row.plate)}>
                <FaMagnifyingGlass />
                Ver Detalles
            </TableActionButton>
        </div>
    );

    const columns = [
        {
            name: 'Placa',
            selector: row => row.plate,
            sortable: true,
            sortField: 'plate',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
        {
            name: 'Tipo de vehículo',
            selector: row => vehicleType(row.type)
        },
        {
            name: 'Acciones',
            width: "150px",
            cell: row => <Actions row={row} />,
        }
    ];

    const filters = [
        {
            column: 'plate',
            input: 'text',
            label: 'Placa',
            operation: 'like',
            length: 20
        },
        {
            column: 'article',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
        {
            column: 'type',
            input: 'select',
            label: 'Tipo de vehículo',
            options: [
                { value: 'CARGO', text: 'Cabezal' },
                { value: 'LIGHT', text: 'Liviano' },
                { value: 'YELLOW', text: 'Amarillo' },
                { value: 'RENTED', text: 'Alquilado' },
            ],
            operation: 'equal'
        },
        {
            column: 'totalValue',
            input: 'range',
            label: 'Valor total',
            operation: 'between',
            length: 15
        },
        {
            column: 'eventDate',
            input: 'dates',
            label: 'Fecha de despacho',
            operation: 'between',
        },
    ];

    const exportOptions = {
        handler: exportIssuesByVehicleReport
    };

    const exportDetailstOptions = {
        handler: exportIssueVehicleDetailsReport
    };


    const vehicleDetailsTableColumns = [
        {
            name: 'Artículo',
            selector: row => row.name,
            sortable: true,
            sortField: 'name',
        },
        {
            name: 'Cantidad',
            selector: row => row.amount,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: 'Precio promedio',
            selector: row => `₡${formatCurrency(row.averageValue)}`,
            sortable: true,
            sortField: 'averageValue',
        },
        {
            name: 'Valor total',
            selector: row => `₡${formatCurrency(row.totalValue)}`,
            sortable: true,
            sortField: 'totalValue',
        },
    ];

    const vehicleDetailsTableFilters = [
        {
            column: 'name',
            input: 'text',
            label: 'Artículo',
            operation: 'like',
            length: 50
        },
    ];

    // ----- Render
    return (
        <div className="container mt-5">
            <h1 className="mb-5">Reporte de Despachos por Placa</h1>

            <TableContainer
                columns={columns}
                fetchDataHandler={getIssuesByVehicleReport}
                updateTable={true}
                filters={filters}
                afterFilteringHandler={handleDataAfterFiltering}
                exportOptions={exportOptions}
            />

            {showVehicleDetailsTable &&
                <div className='my-5'>
                    <h3>Detalles para vehículo con placa: {currentVehicle.plate}</h3>

                    <div className="d-flex justify-content-end">
                        <AddButton className="btn btn-primary" onClick={() => setShowVehicleDetailsTable(false)}>
                            Ocultar detalles
                        </AddButton>
                    </div>

                    <TableContainer
                        columns={vehicleDetailsTableColumns}
                        fetchDataHandler={getVehicleIssuesDetails}
                        updateTable={updateTable}
                        filters={vehicleDetailsTableFilters}
                        pathVariables={currentVehicle}
                        exportOptions={exportDetailstOptions}
                        parentFilters={parentFilters}
                    />
                </div>
            }

        </div>
    )
}

export default ReportIssuesByVehicle;