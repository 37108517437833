import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { styled } from "styled-components";

const MovementInProgressModal = ({ show, closeHandler, actionHandler }) => {
    // ----- Actions
    const handleClose = () => {
        closeHandler();
    }

    const handleAction = () => {
        actionHandler();
    }

    // ----- Render
    return (
        <Modal show={show} backdrop="static" centered onHide={handleClose}>
            <Modal.Header className='fw-bold' closeButton>
                Mensaje de confirmación
            </Modal.Header>
            <Modal.Body>Tiene un movimiento en progreso. Desea salir?</Modal.Body>
            <Modal.Footer>
                <ModalButton className="cancel" onClick={handleClose}>
                    Continuar
                </ModalButton>
                <ModalButton className="action" onClick={handleAction}>
                    Salir
                </ModalButton>
            </Modal.Footer>
        </Modal>
    );
}

const ModalButton = styled(Button)`
    border: none;

    &.cancel {
        background-color: #A5A7A0;
    }
    
    &.action {
        background-color: #D04437;
    }
`;

export default MovementInProgressModal;
